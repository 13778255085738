// Spanish translation file for the i18n module
import { TranslateDto } from "../translate.dto";

export const mx: TranslateDto = {
  // Not found Page
  Not_found_page: "404: Pagina no encontrada",
  //Global
  Go_back: "Volver al inicio",
  // Topbar
  Slogan: "La única gafa 100% personalizable",
  // PutPostCode
  Error_PERMISSION_DENIED:
    "El usuario ha denegado el permiso para la geolocalización.",
  Error_POSITION_UNAVAILABLE:
    "La información de la localización no está disponible.",
  Error_TIMEOUT: "El tiempo de petición ha expirado.",
  Error_UNKNOWN_ERROR: "Ha ocurrido un error desconocido.",
  Error_default: "Ha ocurrido un error desconocido.",
  Search_your_optic: "Busca tu óptica más cercana",
  Description_for_search_optic:
    "Ingresa tu dirección y te ayudaremos a encontrar la óptica más cercana a ti. 👇",
  Alert: "Parece que hay algo mal",
  Placeholder_search_optic: "Escribe tu dirección o código postal",
  Can_I_Have_Your_Ubication: "¿Puedo tener tu ubicación?",
  Description_We_Need_Ubication:
    "Para encontrar la óptica más cercana a ti deberás permitirme acceder a tu ubicación",
  Confirm: "Sí, claro!",
  Denaied: "No, gracias",
  Cant_Accesses_Your_Ubication: "Lo siento, no puedo acceder a tu ubicación",
  Description_Cant_Accesses_Your_Ubication:
    "Parece que has denegado el acceso a tu ubicación. Para encontrar la óptica más cercana a ti deberás escribir tu dirección o código postal en el campo de arriba",
  Accept: "De acuerdo",
  Search_GPS: "Buscando GPS...",
  Use_My_Location: "Usa mi ubicación actual",
  No_Selection_Location: "No has seleccionado ninguna dirección",
  Search: "Buscar",
  Ofert1: "Obtén un 25% de descuento + examen de la vista gratis al agendar tu cita.",
  // Ubication
  Go_to_the_list: "Volver a la lista",
  This_is_your_optics: "Estas son tus ópticas más cercanas:",
  Description_We_Need_Ubication2:
    "Introduce tu dirección en el buscador de arriba para ver tu óptica más cercana",
  Not_Found_Optics: "¡Ups! No hay ópticas cercanas a tu ubicación.",
  Form_to_contact: "También puedes consultarnos por",
  Distance: " Km de distancia",
  Ask_date: "Agendar cita",
  // CardOptic
  Optic_nearest: "Óptica más cercana",
  Of_distance: " de distancia",
  See_map: "Ver mapa",
  // Schedule
  Ofert2: "¡Agenda tu cita y obtén un descuento y un examen de la vista gratis!",
  Choose_optic: "Óptica seleccionada:",
  Direction: "Dirección:",
  Selected_day: "Selecciona un día:",
  Choose_hour: "Elige una hora:",
  Load_more_days: "Cargar más días",
  No_search_schedules: "¡Ups...! No hay citas disponibles",
  Change_optic: "Cambiar de óptica",
  See_more: "Ver más",
  Try_another_week: "También puedes probar otra semana",
  // Hours Container
  No_schedule: "No quedan citas",
  // Client Data
  Form_title: "Llena tus datos para reservar tu cita",
  Receive_ofert: "Recibirás tu oferta por correo o WhatsApp.",
  Your_choose_optic: "Tu óptica seleccionada:",
  Your_choose_date: "Tu fecha elegida:",
  Name: "Nombre",
  Subname: "Apellido",
  Phone: "Teléfono",
  Email: "Correo",
  Incorrect_field: "Campo incorrecto *",
  Next: "Siguiente",
  // Confirmation
  Error_create_appointment: "Hubo un error al crear la cita.",
  Error_update_appointment: "Hubo un error al actualizar la cita.",
  Edit_your_data: "Edita tus datos para cambiar la cita",
  Confirm_for_reserve: "Confirma tus datos para reservar tu cita:",
  Change_my_optic: "Cambiar mi óptica",
  Change_my_date: "Cambiar mi fecha",
  changeMyData: "Cambiar mis datos",
  Your_data: "Tus datos de contacto:",
  Confirm_appointment: "Confirmar cita",
  Edit_my_data: "Editar mis datos.",
  Greetings: "¡Hola",
  Of: "de",
  Await_you: "te estará esperando el día",
  At: "a las",
  If_put: "Si llevas lentillas",
  No_24h: "no te las pongas durante las 24h",
  Pre_exam: "previas al examen",
  Is_easy: "Es muy fácil llegar a:",
  Benefit_appointment_title: "Durante la cita:",
  Benefit_appointment_1: "Revisamos de forma totalmente gratuita",
  Benefit_appointment_2:
    "Podrás probar una amplia selección de colores y accesorios",
  Benefit_appointment_3:
    "Comprobarás cuál de nuestros modelos se adaptan más a tu cara",
  Benefit_appointment_4: "Podrás consultar con tu óptico qué clips necesitas",
  Free: "¡Todo de forma gratuita!",
  Remember:
    "Recuerda que con tu cita reservada no tendrás que hacer cola, además, solo por asistir podrás acceder a la",
  Promotion: "promoción",
  No_assist:
    "En caso de que no puedas asistir o si tienes cualquier duda, puedes contactar con nosotros vía",
  Or: "o",
  Start: "Inicio",
  Until_the: "Hasta el",
  // FAQs
  Question_1: "¿De qué están hechas los lentes FLiPO?",
  Answer_1:
    "Todos nuestros productos están hechos con ULTEM, un material revolucionario por su flexibilidad y resistencia. Puedes consultar la ficha técnica de cada modelo para más detalles. 🤓🔛😎👍",
  Question_2: "¿Cómo funcionan los clips y las patillas?",
  Answer_2:
    "Nuestros clips y varillas tienen un sistema de imanes 🧲 que permite intercambiarlos de manera fácil y segura, sin preocuparte de que se caigan. 🤓🔛😎🙌",
  Question_3: "¿Qué diferencia hay entre el clip funcional y el de diseño?",
  Answer_3:
    "El clip funcional 👓 combina lentes graduadas con diferentes funciones que transforman tus gafas, protegiéndote de la luz azul, el sol y otros rayos dañinos para tu vista. El clip de diseño cambia la apariencia del frente 😎 de tus monturas y, junto con el intercambio de varillas, transforma por completo el estilo de tus gafas. 🤓🔛😎🙌",
  Question_4: "¿Cuánto tiempo tardan en estar listas mis lentes FLiPO monofocales?​",
  Answer_4:
    "Tus lentes estarán listos en 7-10 días hábiles. ¡Listos para usarse y lucirse! 💥",
  Question_5: "¿Cuánto tiempo tardan en estar listas mis lentes FLiPO progresivos?",
  Answer_5:
    "Tus lentes progresivos estarán listos en 7-10 días hábiles. ¡Vale la pena la espera! ⚡️",
};
