import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languageDetector from "./languageDetector";

// Lenguaes
import { es } from "./languages/es";
import { pt } from "./languages/pt";
import { uk } from "./languages/uk";
import { it } from "./languages/it";
import { fr } from "./languages/fr";
import { mx } from "./languages/mx";

import { store } from "../redux/store";

import i18next from "i18next";


//Get storage changes, if the user changes the language, change it in i18next
store.subscribe(() => {
  //If the user has a language in the store, use it
  if (store.getState().language) {
    i18next.changeLanguage(store.getState().language);
  }
});

export const initi18n = () => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(languageDetector)
    .init({
      returnNull: false,
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      resources: {
        es: {
          translation: es,
        },
        pt: {
          translation: pt,
        },
        uk: {
          translation: uk,
        },
        en: {
          translation: uk,
        },
        it: {
          translation: it,
        },
        fr: {
          translation: fr,
        },
        mx: {
          translation: mx,
        },
      },
      //lng: store.getState().language ? store.getState().language : undefined, // if you're using a language detector, do not define the lng option
      fallbackLng: "es", // use en if detected lng is not available
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    });
};
